import { ColorToken } from '@mindfulchefuk/design-system'
import { useCMSPage } from '@mindfulchefuk/features/CMS/hooks/useCMSPage'

type ColorHash = {
  [key: string]: ColorToken
}

const seasonalColors: ColorHash = {
  spring: 'veg20',
  summer: 'veg2',
  autumn: 'veg12',
  winter: 'meat6',
}

export const useHomepageThemeEmphasisText = (): ColorToken => {
  const { data } = useCMSPage('page-homepage')

  if (!data) return seasonalColors.winter

  return seasonalColors[data.theme]
}
